import axios from 'axios';
import { APIErrorValidation, APIPrice, APIHealthCheck, APISocialAccount, APIBlockCategory, APIUser, APIFieldDefinition, APITabField, APIBlock, APIFieldValue, APIConnection, APIEnvironmentVariable, APIBlockEnvironmentVariableOverrides, APIDeploymentVersion, APIDeploymentPlan, APILiveLog, APIFieldBox, APIBaseAttributes, APIUrlRedirection, APISelectOption, APIDeploymentSubject, APIDeploymentRun, APIDeploymentLog, APIPriceRanges, APIClusterNodeTypologie, APICluster, APIProject, APITransaction, APIMetricMatrice, APIDeployment, APIMoney, APIPaymentMethodSetupIntent, APIPaymentMethod, APIInvoice, APIFieldGroup, APIClusterRequirement, APIClusterLocation, APILoadBalancerTypologie } from '@/typesAPI';
import getEnv from '@/utils/env'
import helpers from '@/utils/helpers'
import router from '@/router';
import { RouteRecordRaw } from 'vue-router';
import APICustomer from '@/typesAPI/APICustomer';
import store from '@/store';
import { ToastrDescriptor } from '@/types';
import enums from '@/enums';

const csrfCookieName = 'XSRF-TOKEN'
const instance = axios.create({
  baseURL: getEnv('VUE_APP_API_URL'),
  timeout: 30000,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: function(status: number) {
    return (status >= 200 && status < 300);
  }
});

// const exeptionAutoErrorEndpoints = [/\/me$/];
// const exeptionAutoError = ["resource-not-found"];

instance.interceptors.response.use(
  (res: any) => {
    //OnEverySuccess
    return res;
  },
  (err: any) => {

    if(err?.response?.status > 401 && err?.response?.status !== 404) {
      Object.values(err?.response?.data?.errors).forEach((e:any) => {
        if(e.title === 'validation') {
          Object.values(err?.response?.data?.meta).forEach((meta:any) => {
            const descriptor:ToastrDescriptor = {
              title: '',
              text: meta.join(' '),
              severity: enums.Severity.DANGER,
              id: new Date().getTime().toString()
            }
            store.dispatch('toastrs/addDescriptor', descriptor)
          })
         
        } else {
          const descriptor:ToastrDescriptor = {
            title: e.title,
            text: e.details,
            severity: enums.Severity.DANGER,
            id: new Date().getTime().toString()
          }
          store.dispatch('toastrs/addDescriptor', descriptor)
        }

      })
    }
    if((err?.response?.status === 401)) {
      router.options.routes.forEach((route:RouteRecordRaw) => {

        if(route.children) {

          const child = route.children?.find((c) => c.name === router.currentRoute.value.name)
          if(child) {
            if((route?.props as any)?.needAuth) {
              router.push({name: 'login'})
            }
            if((child?.props as any)?.needAuth) {
              router.push({name: 'login'})
            }
          }
        }

        if(route.name === router.currentRoute.value.name && (route?.props as any)?.needAuth) {
          router.push({name: 'login'})
        }
      })
      
    }
    //OnEveryError
    return Promise.reject(err);
  }
);

function extractFromIncludes(result:any, type:string) {
  return result.data?.included ? result.data?.included.filter((item:any) => item.type === type) : []
} 
function isCSRFCookiePresent() {
  return document.cookie.includes(csrfCookieName + '=')
} 
const API = {
  get instance () {
    return instance
  },
  session: {
    getCSRF() {
      return new Promise((resolve, reject) => {
        if (!isCSRFCookiePresent()) {
          helpers.console.log("Session/getCSRF START")
          instance
          .get("/sanctum/csrf-cookie")
          .then((result) => {
            helpers.console.log("Session/getCSRF SUCCESS : ", result)
            resolve(result)
          })
          .catch((err: any) => {
            helpers.console.warn("Session/getCSRF FAIL : ", err)
            reject(err);
          });
        } else {
          helpers.console.log("CSRF Already present")
          resolve(null)
        }
      });
    },
    login(login: string, password: string) {
      helpers.console.log("Session/login START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/login", {
          login: login,
          password: password
        })
        .then((result: any) => {
          helpers.console.log("Session/login SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/login FAIL : ", err)
          reject(err);
        });
      });
    },
    loginViaSocial(socialProvider: string): Promise<APIUrlRedirection> {
      helpers.console.log("Session/loginViaSocial START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/login/${socialProvider}/redirect`)
        .then((result: any) => {
          helpers.console.log("Session/loginViaSocial SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/loginViaSocial FAIL : ", err)
          reject(err);
        });
      });
    },
    loginViaSocialCallback(socialProvider: string, code:string): Promise<APIUser> {
      helpers.console.log("Session/loginViaSocialCallback START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/login/${socialProvider}/callback`, {
          code : code
        })
        .then((result: any) => {
          helpers.console.log("Session/loginViaSocialCallback SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/loginViaSocialCallback FAIL : ", err)
          reject(err);
        });
      });
    },
    createMagicLink(email: string) {
      helpers.console.log("Session/createMagicLink START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/magicLink`, {email:email})
        .then((result: any) => {
          helpers.console.log("Session/createMagicLink SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/createMagicLink FAIL : ", err)
          reject(err);
        });
      });
    },
    tokenLogin(token: string) {
      helpers.console.log("Session/tokenLogin START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/tokens/${token}`)
        .then((result: any) => {
          helpers.console.log("Session/tokenLogin SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/tokenLogin FAIL : ", err)
          reject(err);
        });
      });
    },
    logout() {
      helpers.console.log("Session/logout START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/logout")
        .then((result: any) => {
          helpers.console.log("Session/logout SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/logout FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  subscription: {
    checkout():Promise<APIUrlRedirection> {
      helpers.console.log("Subscription/checkout START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/checkoutSessions`)
        .then((result: any) => {
          helpers.console.log("Subscription/checkout SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Subscription/checkout FAIL : ", err)
          reject(err);
        });
      });
    },
    manage():Promise<APIUrlRedirection> {
      helpers.console.log("Subscription/manage START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/subscriptions/default`)
        .then((result: any) => {
          helpers.console.log("Subscription/manage SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Subscription/manage FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  user: {
    getMe():Promise<{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}> {
      helpers.console.log("User/me START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/me?include=social_accounts,customers")
        .then((result: any) => {
          helpers.console.log("User/me SUCCESS : ", result)
          resolve({user: result.data.data, socialAccounts: extractFromIncludes(result, 'socialAccounts'), customers: extractFromIncludes(result, 'customers')});
        })
        .catch((err: any) => {
          helpers.console.warn("User/me FAIL : ", err)
          reject(err);
        });
      });
    },
    create(email:string):Promise<APIUser> {
      helpers.console.log("User/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/users", {email:email})
        .then((result: any) => {
          helpers.console.log("User/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("User/create FAIL : ", err)
          reject(err);
        });
      });
    },
    edit(userId:string, form:Partial<APIUser["attributes"]>):Promise<APIUser> {
      helpers.console.log("User/edit START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/users/${userId}`, form)
        .then((result: any) => {
          helpers.console.log("User/edit SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("User/edit FAIL : ", err)
          reject(err);
        });
      });
    },
    acceptCGU(userId:string):Promise<APIUser> {
      helpers.console.log("User/acceptCGU START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/users/${userId}`, {terms_of_use_accepted:true})
        .then((result: any) => {
          helpers.console.log("User/acceptCGU SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("User/acceptCGU FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  prices: {
    getMonthly(projectId:string):Promise<APIPriceRanges> {
      helpers.console.log("Prices/getMonthly START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/projects/${projectId}/monthlyPrice`)
        .then((result: any) => {
          helpers.console.log("Prices/getMonthly SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Prices/getMonthly FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  projects: {
    getList():Promise<APIProject[]> {
      helpers.console.log("Projects/getList START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/projects`)
        .then((result: any) => {
          helpers.console.log("Projects/getList SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Projects/getList FAIL : ", err)
          reject(err);
        });
      });
    },
    create():Promise<APIProject> {
      helpers.console.log("Projects/create START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/projects`, {name : 'New Project'})
        .then((result: any) => {
          helpers.console.log("Projects/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Projects/create FAIL : ", err)
          reject(err);
        });
      });
    },
    edit(projectId:string, form:Partial<APIProject['attributes']>):Promise<APIProject> {
      helpers.console.log("Projects/edit START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/projects/${projectId}`, form)
        .then((result: any) => {
          helpers.console.log("Projects/edit SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Projects/edit FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(projectId:string):Promise<null> {
      helpers.console.log("Projects/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/projects/${projectId}`)
        .then((result: any) => {
          helpers.console.log("Projects/delete SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("Projects/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    putOffline(projectId:string):Promise<null> {
      helpers.console.log("Projects/putOffline START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/projects/${projectId}/destroy`)
        .then((result: any) => {
          helpers.console.log("Projects/putOffline SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("Projects/putOffline FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  clusters: {
    getClustersByProject(projectId:string):Promise<APICluster[]> {
      helpers.console.log("Clusters/getClustersByProject START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/projects/${projectId}/kubernetesClusters`)
        .then((result: any) => {
          helpers.console.log("Clusters/getClustersByProject SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getClustersByProject FAIL : ", err)
          reject(err);
        });
      });
    },
    getClustersByCustomer(customerId:string):Promise<{clusters:APICluster[], deployments:APIDeployment[]}> {
      helpers.console.log("Clusters/getClustersByCustomer START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/customers/${customerId}/kubernetesClusters?include=deployment`)
        .then((result: any) => {
          helpers.console.log("Clusters/getClustersByCustomer SUCCESS : ", result)
          resolve({clusters:result.data.data, deployments: extractFromIncludes(result, 'deployments')});
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getClustersByCustomer FAIL : ", err)
          reject(err);
        });
      });
    },
    getProjectsByCluster(clusterId:string):Promise<APIProject[]> {
      helpers.console.log("Clusters/getProjectsByCluster START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/kubernetesClusters/${clusterId}/projects `)
        .then((result: any) => {
          helpers.console.log("Clusters/getProjectsByCluster SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getProjectsByCluster FAIL : ", err)
          reject(err);
        });
      });
    },
    editCluster(clusterId:string, config:Partial<APICluster['attributes']> & {deploy_now:boolean}):Promise<APICluster> {
      helpers.console.log("Clusters/editCluster START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/kubernetesClusters/${clusterId}`, config)
        .then((result: any) => {
          helpers.console.log("Clusters/editCluster SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/editCluster FAIL : ", err)
          reject(err);
        });
      });
    },
    deleteCluster(clusterId:string):Promise<null> {
      helpers.console.log("Clusters/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/kubernetesClusters/${clusterId}`)
        .then((result: any) => {
          helpers.console.log("Clusters/delete SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    getClusterNodeTypologiesByProvider(provider:string):Promise<APIClusterNodeTypologie[]> {
      helpers.console.log("Clusters/getClusterNodeTypologiesByProvider START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/kubernetesClusters/${provider}/nodeTypologies`)
        .then((result: any) => {
          helpers.console.log("Clusters/getClusterNodeTypologiesByProvider SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getClusterNodeTypologiesByProvider FAIL : ", err)
          reject(err);
        });
      });
    },
    getLoadBalancerTypologiesByProvider(provider:string):Promise<APILoadBalancerTypologie[]> {
      helpers.console.log("Clusters/getLoadBalancerTypologiesByProvider START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/kubernetesClusters/${provider}/loadBalancerTypologies`)
        .then((result: any) => {
          helpers.console.log("Clusters/getLoadBalancerTypologiesByProvider SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getLoadBalancerTypologiesByProvider FAIL : ", err)
          reject(err);
        });
      });
    },
    getLocationsByProvider(provider:string):Promise<APIClusterLocation[]> {
      helpers.console.log("Clusters/getLocationsByProvider START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/kubernetesClusters/${provider}/locations`)
        .then((result: any) => {
          helpers.console.log("Clusters/getLocationsByProvider SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getLocationsByProvider FAIL : ", err)
          reject(err);
        });
      });
    },
    getClusterRequirementsByProviderAndLocationId(provider:string, locationId:string):Promise<APIClusterRequirement[]> {
      helpers.console.log("Clusters/getClusterRequirementsByProviderAndLocationId START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/kubernetesClusters/${provider}/requirements`, {params: {location : locationId}})
        .then((result: any) => {
          helpers.console.log("Clusters/getClusterRequirementsByProviderAndLocationId SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/getClusterRequirementsByProviderAndLocationId FAIL : ", err)
          reject(err);
        });
      });
    },
    putOffline(clusterId:string, projectIdList:string[]):Promise<null> {
      helpers.console.log("Clusters/putOffline START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/kubernetesClusters/${clusterId}/destroy`, {
          projects_to_be_deleted: projectIdList
        })
        .then((result: any) => {
          helpers.console.log("Clusters/putOffline SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/putOffline FAIL : ", err)
          reject(err);
        });
      });
    },
    create(customerId:string, form:Partial<APICluster['attributes'] & {deploy_now:boolean}>):Promise<APICluster> {
      helpers.console.log("Clusters/create START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/customers/${customerId}/kubernetesClusters`, form)
        .then((result: any) => {
          helpers.console.log("Clusters/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Clusters/create FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  fields: {
    getOne(fieldId:string, includes?:string): Promise<{fieldValue:APIFieldValue, box:APIFieldBox , fieldDefinition:APIFieldDefinition, tabs:APITabField[]}> {
      helpers.console.log("Fields/getOne START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/fields/${fieldId}?include=`+ (includes ? includes : "definition.box,block.tabs"))
        .then((result: any) => {
          helpers.console.log("Fields/getOne SUCCESS : ", result)
          const box = extractFromIncludes(result, 'fieldBoxes')[0]
          const definition = extractFromIncludes(result, 'fieldDefinitions')[0]
          const tabs = extractFromIncludes(result, 'blockTabs')

          resolve({fieldValue: result.data.data, box:box, fieldDefinition: definition, tabs: tabs});
        })
        .catch((err: any) => {
          helpers.console.warn("Fields/getOne FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  blocks: {
    getIndex(projectId:string, includes?:string): Promise<{blocks:APIBlock[], fieldsTabs: APITabField[], connections: APIConnection[], deployments:APIDeployment[]}> {
      helpers.console.log("Blocks/getIndex START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/projects/"+projectId+"/blocks?include="+ (includes ? includes : "tabs,current_connections,deployments"))
        .then((result: any) => {
          helpers.console.log("Blocks/getIndex SUCCESS : ", result)
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const connections = extractFromIncludes(result, 'blockConnections')
          // const boxes = extractFromIncludes(result, 'fieldBoxes')
          const deployments = extractFromIncludes(result, 'deployments')

          resolve({blocks: result.data.data, fieldsTabs:fieldsTabs, connections: connections, deployments:deployments});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getIndex FAIL : ", err)
          reject(err);
        });
      });
    },
    getBlockFields(blockId:string, tabId?:string): Promise<{values: APIFieldValue[], boxes:APIFieldBox[], definitions: APIFieldDefinition[], groups: APIFieldGroup[]}> {
      helpers.console.log("Blocks/getBlockFields START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/blocks/${blockId}/fields?include=definition.box,group`+ (tabId? `&tabs=${tabId}` : ''))
        .then((result: any) => {
          helpers.console.log("Blocks/getBlockFields SUCCESS : ", result)
          const boxes = extractFromIncludes(result, 'fieldBoxes')
          const definitions = extractFromIncludes(result, 'fieldDefinitions')
          const groups = extractFromIncludes(result, 'fieldGroups')

          resolve({values : result.data.data, boxes: boxes, definitions: definitions, groups: groups});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getBlockFields FAIL : ", err)
          reject(err);
        });
      });
    },
    getCategories(): Promise<{blockCategories:APIBlockCategory[], definitions: APIFieldDefinition[]}> {
      helpers.console.log("Blocks/getCategories START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blockCategories?include=preselect_field_definition")
        .then((result: any) => {
          helpers.console.log("Blocks/getCategories SUCCESS : ", result)
          resolve({
            blockCategories: result.data.data as APIBlockCategory[],
            definitions: result.data.included as APIFieldDefinition[]
          });
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getCategories FAIL : ", err)
          reject(err);
        });
      });
    },
    create(projectId:string, blockCategoryID:string, latitude:number, longitude: number): Promise<{block:APIBlock, fieldsValue:APIFieldValue[], fieldsDefinition:APIFieldDefinition[], fieldsTabs: APITabField[], fieldsBoxes:APIFieldBox[]}> {
      helpers.console.log("Blocks/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/projects/"+projectId+"/blockCategories/" + blockCategoryID + "/blocks?include=fields,fields.definition,fields.definition.box,fields.definition.box.tab", {latitude : parseInt(latitude.toString()), longitude : parseInt(longitude.toString())})
        .then((result: any) => {
          helpers.console.log("Blocks/create SUCCESS : ", result)
          const fieldsValue = extractFromIncludes(result, 'fields')
          const fieldsDefinition = extractFromIncludes(result, 'fieldDefinitions')
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const fieldsBoxes = extractFromIncludes(result, 'fieldBoxes')
          
          resolve({block: result.data.data, fieldsValue: fieldsValue, fieldsDefinition:fieldsDefinition, fieldsTabs:fieldsTabs, fieldsBoxes:fieldsBoxes});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(blockAPIID:string): Promise<any> {
      helpers.console.log("Blocks/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blocks/" + blockAPIID)
        .then((result: any) => {
          helpers.console.log("Blocks/delete SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    editBlockName(blockAPIID:string, name:string): Promise<any> {
      helpers.console.log("Blocks/editBlockName START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blocks/" + blockAPIID, {name:name})
        .then((result: any) => {
          helpers.console.log("Blocks/editBlockName SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editBlockName FAIL : ", err)
          reject(err);
        });
      });
    },
    editBlockPosition(blockAPIID:string, latitude:number, longitude: number): Promise<any> {
      helpers.console.log("Blocks/editBlockPosition START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blocks/" + blockAPIID, {latitude:latitude, longitude: longitude})
        .then((result: any) => {
          helpers.console.log("Blocks/editBlockPosition SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editBlockPosition FAIL : ", err)
          reject(err);
        });
      });
    },
    editFieldValue(fieldValueID:string, key:string, value:string): Promise<{newFieldValue:APIFieldValue ,blocks:APIBlock[], fieldsValue:APIFieldValue[], fieldsDefinition:APIFieldDefinition[], fieldsTabs: APITabField[], fieldsBoxes:APIFieldBox[]}> {
      helpers.console.log("Blocks/editFieldValue START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/fields/" + fieldValueID + '?include=block,block.fields,block.fields.definition,block.fields.definition.box,block.fields.definition.box.tab', {value:value})
        .then((result: any) => {
          helpers.console.log("Blocks/editFieldValue SUCCESS : ", result)
          const blocks = extractFromIncludes(result, 'blocks')
          const fieldsValue = extractFromIncludes(result, 'fields')
          const fieldsDefinition = extractFromIncludes(result, 'fieldDefinitions')
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const fieldsBoxes = extractFromIncludes(result, 'fieldBoxes')
          resolve({newFieldValue: result.data.data, blocks: blocks, fieldsValue: fieldsValue, fieldsDefinition:fieldsDefinition, fieldsTabs:fieldsTabs, fieldsBoxes: fieldsBoxes});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editFieldValue FAIL : ", err)
          reject(err);
        });
      });
    },
    getHealthChecks(blockId:string): Promise<APIHealthCheck[]> {
      helpers.console.log("Blocks/getHealthChecks START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/blocks/${blockId}/healthChecks`)
        .then((result: any) => {
          helpers.console.log("Blocks/getHealthChecks SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getHealthChecks FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  connections: {
    create(firstBlockAPIID: string, secondBlockAPIID:string): Promise<APIConnection> {
      helpers.console.log("Connections/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/blockConnections", { first_block_id: firstBlockAPIID, second_block_id: secondBlockAPIID })
        .then((result: any) => {
          helpers.console.log("Connections/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Connections/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(connectionID: string): Promise<any> {
      helpers.console.log("Connections/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blockConnections/" + connectionID)
        .then((result: any) => {
          helpers.console.log("Connections/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Connections/delete FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  actions : {
    validationErrors(projectId:string): Promise<APIErrorValidation[]> {
      helpers.console.log("Actions/checkErrors START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/projects/" + projectId + "/validationErrors")
        .then((result: any) => {
          helpers.console.log("Actions/checkErrors SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Actions/checkErrors FAIL : ", err)
          reject(err);
        });
      });
    },
   
  },
  search: {
    fieldSelectValues(fieldId:string, query:string): Promise<APISelectOption[]> {
      helpers.console.log("Search/fieldSelectValues START")
      return new Promise((resolve, reject) => {
        const queryString = query ? `?query=${query}` : ''
        instance
        .get(`/alpha/selects/${fieldId}${queryString}`)
        .then((result: any) => {
          helpers.console.log("Search/fieldSelectValues SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Search/fieldSelectValues FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  buttons: {
    post(fieldId:string): Promise<any> {
      helpers.console.log("Buttons/post START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/buttons/${fieldId}`)
        .then((result: any) => {
          helpers.console.log("Buttons/post SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Buttons/post FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  group: {
    create(fieldDefinitionId:string, body:{[key:string]:string}) {
      helpers.console.log("Group/create START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/fields/${fieldDefinitionId}/fieldGroups?include=fields`, body)
        .then((result: any) => {
          helpers.console.log("Group/create SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Group/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(fieldGroupId:string) {
      helpers.console.log("Group/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/fieldGroups/${fieldGroupId}`)
        .then((result: any) => {
          helpers.console.log("Group/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Group/delete FAIL : ", err)
          reject(err);
        });
      });
    }
  },
  social: {
    connect(userId:string, socialProvider:string, redirectUrl:string): Promise<APIUrlRedirection> {
      helpers.console.log("Social/connect START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/users/${userId}/socialAccounts/${socialProvider}/redirect`,{
          params: {
            redirect_url: redirectUrl,
          }
        })
        .then((result: any) => {
          helpers.console.log("Social/connect SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/connect FAIL : ", err)
          reject(err);
        });
      });
    },
    callback(userId:string, socialProvider:string, code:string, redirectUrl:string): Promise<APISocialAccount> {
      helpers.console.log("Social/callback START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/users/${userId}/socialAccounts/${socialProvider}/callback`, {code: code, redirect_url:redirectUrl})
        .then((result: any) => {
          helpers.console.log("Social/callback SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/callback FAIL : ", err)
          reject(err);
        });
      });
    },
    get(accountId:string): Promise<APISocialAccount> {
      helpers.console.log("Social/get START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/socialAccounts/${accountId}`)
        .then((result: any) => {
          helpers.console.log("Social/get SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/get FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(accountId:string): Promise<any> {
      helpers.console.log("Social/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/socialAccounts/${accountId}`)
        .then((result: any) => {
          helpers.console.log("Social/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/delete FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  environmentVariables: {
    get(blockID:string): Promise<any> {
      helpers.console.log("EnvironmentVariables/get START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blocks/" + blockID + "/blockEnvironmentVariables")
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/get SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/get FAIL : ", err)
          reject(err);
        });
      });
    },
    create(blockID:string, attributes:APIEnvironmentVariable['attributes']): Promise<APIEnvironmentVariable> {
      helpers.console.log("EnvironmentVariables/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/blocks/" + blockID + "/blockEnvironmentVariables", attributes)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/create FAIL : ", err)
          reject(err);
        });
      });
    },
    edit(variableID:string, attributes:APIEnvironmentVariable['attributes']): Promise<any> {
      helpers.console.log("EnvironmentVariables/edit START")
      const payload:any = attributes
      Object.keys(payload).forEach((key:string) => {
        if(payload[key] === null) {
          delete payload[key]
        }
      })
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blockEnvironmentVariables/"+ variableID, payload)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/edit SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/edit FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(variableID:string): Promise<null> {
      helpers.console.log("EnvironmentVariables/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blockEnvironmentVariables/"+ variableID)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/delete SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    getOverride(blockConnectionID:string): Promise<APIBlockEnvironmentVariableOverrides[]> {
      helpers.console.log("EnvironmentVariables/getOverride START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blockConnections/" + blockConnectionID + "/blockEnvironmentVariableOverrides")
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/getOverride SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/getOverride FAIL : ", err)
          reject(err);
        });
      });
    },
    editOverride(overrideID:string, newName:string): Promise<any> {
      helpers.console.log("EnvironmentVariables/editOverride START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blockEnvironmentVariableOverrides/" + overrideID, {name: newName})
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/editOverride SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/editOverride FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  sockets: {
    auth(callback:any, payload:any):any {
      instance
      .post("/broadcasting/auth", payload)
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        callback(error);
      });
    }
  },
  deployment: {
    getLogs(deploymentPlanId:string): Promise<APIDeploymentLog[]> {
      helpers.console.log("Deployment/getLogs START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/deploymentPlans/${deploymentPlanId}/logs`)
        .then((result: any) => {
          helpers.console.log("Deployment/getLogs SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getLogs FAIL : ", err)
          reject(err);
        });
      });
    },
    editDeploymentVersion(deploymentVersionId:string, form:Partial<APIDeploymentVersion>): Promise<APIDeploymentVersion> {
      helpers.console.log("Deployment/editDeploymentVersion START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/deploymentVersions/${deploymentVersionId}`, form)
        .then((result: any) => {
          helpers.console.log("Deployment/editDeploymentVersion SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/editDeploymentVersion FAIL : ", err)
          reject(err);
        });
      });
    },
    getVersionsByDeployment(deploymentId:string): Promise<{deploymentVersions:APIDeploymentVersion[], deploymentPlans:APIDeploymentPlan[], subjects:APIDeploymentSubject[]}> {
      helpers.console.log("Deployment/getVersionsByDeployment START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/deployments/${deploymentId}/deploymentVersions?include=deploymentPlans.subject`, {params: {status : ['draft', 'running']}})
        .then((result: any) => {
          helpers.console.log("Deployment/getVersionsByDeployment SUCCESS : ", result)
          const deploymentPlans = extractFromIncludes(result, 'deploymentPlans')
          const subjects = extractFromIncludes(result, 'blocks')
          resolve({deploymentVersions: result.data.data, deploymentPlans: deploymentPlans, subjects:subjects});
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getVersionsByDeployment FAIL : ", err)
          reject(err);
        });
      });
    },
    getVersionsByProject(projectId:string): Promise<{deploymentVersions:APIDeploymentVersion[], deploymentPlans:APIDeploymentPlan[], subjects:APIDeploymentSubject[]}> {
      helpers.console.log("Deployment/getVersionsByProject START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/projects/${projectId}/deploymentVersions?include=deploymentPlans.subject`, {params: {status : ['draft', 'running']}})
        .then((result: any) => {
          helpers.console.log("Deployment/getVersionsByProject SUCCESS : ", result)
          const deploymentPlans = extractFromIncludes(result, 'deploymentPlans')
          const subjects = extractFromIncludes(result, 'blocks')
          resolve({deploymentVersions: result.data.data, deploymentPlans: deploymentPlans, subjects:subjects});
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getVersionsByProject FAIL : ", err)
          reject(err);
        });
      });
    },
    getDeploymentPlans(deploymentVersionId:string): Promise<{deploymentPlans:APIDeploymentPlan[], subjects:APIDeploymentSubject[]}> {
      helpers.console.log("Deployment/getDeploymentPlans START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/deploymentVersions/${deploymentVersionId}/deploymentPlans?include=subject`)
        .then((result: any) => {
          helpers.console.log("Deployment/getDeploymentPlans SUCCESS : ", result)
          resolve({
            deploymentPlans: result.data.data,
            subjects:result.data.included,
          });
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getDeploymentPlans FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  billing: {
    addCredit(customerId:string, amount:number): Promise<{transaction:APITransaction, stripePublicKey:string}> {
      helpers.console.log("Billing/addCredit START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/customers/${customerId}/transactions`, {amount :amount})
        .then((result: any) => {
          helpers.console.log("Billing/addCredit SUCCESS : ", result)
          resolve({transaction:result.data.data, stripePublicKey: result.data.meta.stripe_public_key});
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/addCredit FAIL : ", err)
          reject(err);
        });
      });
    },
    getBurnRate(customerId:string): Promise<APIMoney> {
      helpers.console.log("Billing/getBurnRate START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/customers/${customerId}/burnRate`)
        .then((result: any) => {
          helpers.console.log("Billing/getBurnRate SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/getBurnRate FAIL : ", err)
          reject(err);
        });
      });
    },
    getCurrentBillingCycle(customerId:string): Promise<APIPrice[]> {
      helpers.console.log("Billing/getCurrentBillingCycle START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/customers/${customerId}/charges`)
        .then((result: any) => {
          helpers.console.log("Billing/getCurrentBillingCycle SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/getCurrentBillingCycle FAIL : ", err)
          reject(err);
        });
      });
    },
    getInvoices(customerId:string): Promise<APIPrice[]> {
      helpers.console.log("Billing/getInvoices START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/customers/${customerId}/invoices`)
        .then((result: any) => {
          helpers.console.log("Billing/getInvoices SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/getInvoices FAIL : ", err)
          reject(err);
        });
      });
    },
    editCustomer(customerId:string, form:Partial<APICustomer["attributes"]>): Promise<APICustomer> {
      helpers.console.log("Billing/editCustomer START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/customers/${customerId}`, form)
        .then((result: any) => {
          helpers.console.log("Billing/editCustomer SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/editCustomer FAIL : ", err)
          reject(err);
        })
      });
    },
    getPaymentMethods(customerId:string):Promise<APIPaymentMethod[]> {
      helpers.console.log("Billing/getPaymentMethods START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/customers/${customerId}/paymentMethods`)
        .then((result: any) => {
          helpers.console.log("Billing/getPaymentMethods SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/getPaymentMethods FAIL : ", err)
          reject(err);
        });
      });
    },
    addPaymentMethods(customerId:string):Promise<{setupIntent:APIPaymentMethodSetupIntent, stripePublicKey:string}> {
      helpers.console.log("Billing/addPaymentMethods START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/customers/${customerId}/paymentMethods`)
        .then((result: any) => {
          helpers.console.log("Billing/addPaymentMethods SUCCESS : ", result)
          resolve({setupIntent:result.data.data, stripePublicKey: result.data.meta.stripe_public_key});
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/addPaymentMethods FAIL : ", err)
          reject(err);
        });
      });
    },
    editPaymentMethod(paymentMethodId:string, form:Partial<APIPaymentMethod["attributes"]>): Promise<APIPaymentMethod> {
      helpers.console.log("Billing/editPaymentMethod START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/paymentMethods/${paymentMethodId}`, form)
        .then((result: any) => {
          helpers.console.log("Billing/editPaymentMethod SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/editPaymentMethod FAIL : ", err)
          reject(err);
        })
      });
    },
    createDraftInvoiceCredits(customerId:string, creditAmount:number, paymentMethodId?:string, promoCode?:string[]):Promise<APIInvoice> {
      helpers.console.log("Billing/createDraftInvoice START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/customers/${customerId}/invoices`, {
          type: "credits",
          quantity: creditAmount,
          payment_method: paymentMethodId,
          discounts: promoCode ? promoCode : []
        })
        .then((result: any) => {
          helpers.console.log("Billing/createDraftInvoice SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/createDraftInvoice FAIL : ", err)
          reject(err);
        });
      });
    },
    editDraftInvoiceCredits(invoiceId:string, creditAmount:number, paymentMethodId?:string, promoCode?:string[]):Promise<APIInvoice> {
      helpers.console.log("Billing/editDraftInvoiceCredits START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/invoices/${invoiceId}`, {
          quantity: creditAmount,
          payment_method: paymentMethodId,
          discounts: promoCode ? promoCode : []
        })
        .then((result: any) => {
          helpers.console.log("Billing/editDraftInvoiceCredits SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/editDraftInvoiceCredits FAIL : ", err)
          reject(err);
        });
      });
    },
    finaliseDraftInvoiceCredits(invoiceId:string, creditAmount:number,paymentMethodId:string, promoCode?:string[]):Promise<APIInvoice> {
      helpers.console.log("Billing/finaliseDraftInvoiceCredits START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/invoices/${invoiceId}`, {
          status: "open",
          quantity: creditAmount,
          payment_method: paymentMethodId,
          discounts: promoCode ? promoCode : []
        })
        .then((result: any) => {
          helpers.console.log("Billing/finaliseDraftInvoiceCredits SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Billing/finaliseDraftInvoiceCredits FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  monitoring: {
    liveLogs(blockId:string, from?:string | null, limit?:number | null, order?:string | null, query?:string | null): Promise<{logList:APILiveLog[], total:number}> {
      helpers.console.log("Monitoring/liveLogs START")

      const params:any =  {}
      if(query) params.query = query
      if(from) params.from = from
      if(limit) params.limit = limit
      if(order) params.order = order

      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/blocks/${blockId}/liveLogs`, { params: params })
        .then((result: any) => {
          helpers.console.log("Monitoring/liveLogs SUCCESS : ", result)
          resolve({logList: result.data.data, total: result.data.meta.total});
        })
        .catch((err: any) => {
          helpers.console.warn("Monitoring/liveLogs FAIL : ", err)
          reject(err);
        });
      });
    },
    metrics(blockId:string, start?:Date | null, end?:Date | null): Promise<{metrics: APIMetricMatrice[], step:number}> {
      helpers.console.log("Monitoring/metrics START")

      const now = new Date()
      const then = new Date(new Date().setDate((start ? start : now).getDate() + 1))
      const params:any =  {
        start : start ? start : now,
        end  : end ? end : then,
      }

      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/blocks/${blockId}/metrics`, { params: params })
        .then((result: any) => {
          helpers.console.log("Monitoring/metrics SUCCESS : ", result)
          resolve({metrics: result.data.data, step: result.data.meta.step});
        })
        .catch((err: any) => {
          helpers.console.warn("Monitoring/metrics FAIL : ", err)
          reject(err);
        });
      });
    },
  }
};

export default API;
