<template>
  <div 
    class="block-node__hoverable"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div 
      class="block-node _node "
      :class="{
        'suggest-connection' : isConnectionSuggested,
        'started-connection' : isStartOfConnection,
        'is-dragged' : isDragged,
        'is-disappearing': isDisappearing,
        needToAppear: needToAppear,
        'in-error' : isValidationErrorHovered ? $store.getters['errorsValidation/getHoveredError'].meta.severity === 'error' : false,
        'in-warning' : isValidationErrorHovered ? $store.getters['errorsValidation/getHoveredError'].meta.severity === 'warning' : false,
        'in-info' : isValidationErrorHovered ? $store.getters['errorsValidation/getHoveredError'].meta.severity === 'info' : false,
      }"
      @click="onClick"
    >
      <v-row class="ma-0 title">
        <div>
          <Logo v-if="blockAPI && $enums.EnginesLogos[blockAPI.attributes.default_name]" :logo="$enums.EnginesLogos[blockAPI.attributes.default_name]" />
          <Icon v-else-if="block.icon" :icon="block.icon" />
          <Logo v-else-if="block.logo" :logo="block.logo" />
        </div>
        <div>
          {{blockName}}
        </div>
      </v-row>
      <!-- <v-row>
        <v-col class="pl-6">
          blablabla 
        </v-col>
      </v-row> -->
      <Handle 
        class="handle handle-left"
        type="source" 
        :position="Position.Left"  
      >
        <div class="handle-display">
          <div class="handle-continuum"></div>
        </div>
      </Handle>
      <Handle 
        class="handle handle-right"
        type="source"
        :position="Position.Right"
      >

        <div class="handle-display">
          <div class="handle-continuum"></div>
        </div>
      </Handle>

      <Handle 
        class="handle handle-top"
        type="source"
        :position="Position.Top"
      >
        <div class="handle-display">
          <div class="handle-continuum"></div>
        </div>
      </Handle>
      <Handle 
        class="handle handle-bottom"
        type="source"
        :position="Position.Bottom"
      >
        <div class="handle-display">
          <div class="handle-continuum"></div>
        </div>
      </Handle>
      <Handle 
        class="handle handle-middle"
        type="source" 
        :class="{'is-connecting' : isStartOfConnection, 'is-link-selected' : isLinkRightSelected || isLinkRightHovered}"
        :position="Position.Right"  
      >
        <div class="handle-inside-circle"></div>
        <div class="handle-interceptor"
            draggable="true" 
            @drag="dragLink($event, 'target')"
            @dragend="dragLinkEnd"
            @dragstart="dragLinkStart($event, 'target')"
        >
        </div>
      </Handle>
      <div class="indicator-status" @click="onStatusIndicatorClick" ref="indicatorRef" v-if="!noStatus && blockStatusIcon" :class="{'no-pointer': !authoriseHealthCheck}">
        <Icon :icon="blockStatusIcon" />
        <Popover v-model:visible="showHealthCheck" :target="$refs.indicatorRef" :closeOnExternalClick="true">
          <template #header>
            <v-col class="pa-0" >
              Health checks
            </v-col>
            <v-col class="pa-0">
              <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="showHealthCheck = false"/>
            </v-col>
          </template>
          <template #content>
            <v-col class="pa-0">
              <v-row 
                class="ma-0"  
                v-for="healthCheck of healthChecks"
                :key="'healthCheck-' + healthCheck.id" 
              >
                <ListElement
                  :icon="translateHealthCheckStatusToIcon(healthCheck.attributes.status)"
                  :noHover="true"
                  :disabledButColored="true"
                  class="ml-0"
                >
                  <template #content>
                    {{ healthCheck.attributes.message }}
                  </template>
                </ListElement>
              </v-row>
            </v-col>
          </template>
        </Popover>
      </div>
    </div>
    <div 
      v-if="!isDisappearing"
      class="btn-plus round-icon-btn"
      :class="{'btn-deployed': IsButtonDeployed, needToAppear: needToAppear}"
      @click="showBlockBar = true"
    >
      <Icon icon="add" :fullSize='true'/>
  </div>
    <BlockBarContext 
      v-if="(showBlockBar && isHovered && !isDisappearing)"
      class="block-bar-context"
      :positionXY="positionXYNewBlock"
      :linkSrc="props.id"
      @addNode="onAddNode"
    />
  </div>
</template>

<script lang="ts">
import { unref } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';
import { NodeProps, Position, Handle, Connection, useVueFlow, StartHandle, NodeDragEvent, Rect } from '@vue-flow/core'
import BlockBarContext from '@/components/Blocks/BlockBarContext.vue';
import { Watch } from 'vue-property-decorator'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import { Block, Element, ElementNode } from '@/types';
import { APIBaseAttributes, APIBlock, APIErrorValidation, APIHealthCheck } from '@/typesAPI';
import Popover from '@/components/UIElements/Popover.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import ListElement from '@/components/UIElements/ListElement.vue';
import API from '@/api/wrapper';

class Props {
  props: NodeProps = prop({
    required: true,
  });
  forcedBlockAPIList: APIBlock[] | undefined = prop({
    required: false,
  });
  forcedNodesList: ElementNode[] | undefined = prop({
    required: false,
  });
  noStatus?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Handle,
    BlockBarContext,
    Icon,
    Logo,
    Popover,
    CustomButton,
    ListElement
  },
})
export default class BlockNode extends Vue.with(Props) {
  Position:typeof Position = Position
  isHovered = false
  isDraggedOver = false
  isDragged = false
  showBlockBar = false
  showHealthCheck = false
  healthChecks:APIHealthCheck[] = []

  vueFlowState = useVueFlow()

  created(): void {
    this.vueFlowState.onNodeDrag((event:NodeDragEvent) => {
      this.isDragged = event.node.id === this.props.id
    })
    this.vueFlowState.onNodeDragStop((event:NodeDragEvent) => {
      this.isDragged = false
    })
    
    this.$emitter.off('dragLink')
    this.$emitter.on('dragLink', (payload:{x: number, y:number, sourceOrTarget:string}) => {
      const linkRect = {width : 1, height : 1, ...this.vueFlowState.project({x: payload.x, y: payload.y}) }
      this.isDraggedOver = this.vueFlowState.isNodeIntersecting(this.nodeRect, linkRect)
    })

  }

  dragLink(event:DragEvent, source:string) {
    this.$emitter.emit('dragLink', {x: event.x, y: event.y, sourceOrTarget: source})
  }

  dragLinkStart(event:DragEvent, sourceOrTarget:string) {
    this.$store.dispatch('elements/setDraggedLink', { linkID: this.$store.getters['elements/getSelectedLink'].id, sourceOrTarget: sourceOrTarget })
  }

  dragLinkEnd(event:DragEvent) {
    this.$store.dispatch('elements/setDraggedLink')
  }

  translateHealthCheckStatusToIcon(status:string) {
    return this.$helpers.states.translateHealthCheckStatusToIcon(status)
  }

  translateDeploymentStatusToSeverity(status:string):string {
    return this.$helpers.states.translateDeploymentStatusToSeverity(status)
  }

  get validationErrors(): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getBySourceID'](this.blockAPIID)
  }

  get pictoWarningSeverity(): string {
    let ret = ''
    this.validationErrors.forEach((err:APIErrorValidation) => {
      if(err.meta.severity === 'error') {
        ret = 'error'
      } else if(err.meta.severity === 'warning' && ret !== 'error') {
        ret = 'warning'
      }
    })

    return ret ? ret : 'info'
  }

  get node(): ElementNode | undefined  {
    const nodes = this.forcedNodesList ? this.forcedNodesList : this.$store.getters['elements/getNodes']
    return nodes.find((node:ElementNode) => node.id === this.props.id)
  }

  get nodeRect(): Rect  {
    return { ...this.props.dimensions, ...this.props.position}
  }

  get block(): Block {
    const nodes = this.forcedNodesList ? this.forcedNodesList : this.$store.getters['elements/getNodes']
    const node = nodes.find((node:Element) => node.id === this.props.id)
    return node && node.extra ? node.extra.block : {label : '', id : this.props.id}
  }

  get blockAPIID(): string {
    const nodes =  this.forcedNodesList ? this.forcedNodesList : this.$store.getters['elements/getNodes']
    const node = nodes.find((node:Element) => node.id === this.props.id)
    return node && node.extra ? node.extra.blockAPIID : ''
  }

  get blockAPI(): APIBlock {
    const blocksAPI = this.forcedBlockAPIList ? this.forcedBlockAPIList: this.$store.getters['blocksAPI/getList']
    return blocksAPI.find((b:APIBlock) => b.id === this.blockAPIID)
  }

  get blockStatusIcon():string {
    return this.$helpers.states.translateDeploymentStatusToIcon(this.blockStatus)
  }
  get blockStatus():string {
    return this.$store.getters['blocksAPI/getDeployementStatusByBlockID'](this.blockAPI.id)
  }

  get blockName(): string {
    return this.blockAPI?.attributes?.name ? this.blockAPI.attributes.name : this.blockAPI?.attributes?.default_name
  }

  get isValidationErrorHovered(): boolean  {
    let ret = false
    const error:APIErrorValidation | null = this.$store.getters['errorsValidation/getHoveredError']
    if(error) {
      const tree = this.$store.getters['fields/getTree']
      let blockIdToSearch = ''
      switch(error.relationships.source.data.type) {
        case 'block':
          blockIdToSearch = error.relationships.source.data.id
          break;
        case 'fields':
          blockIdToSearch = tree.fieldValues[error.relationships.source.data.id]?.relationships?.block.data.id
          break;
        default:
          blockIdToSearch = error.relationships.source.data.id
          break;
      }

      if(blockIdToSearch === this.blockAPIID) {
        ret = true
      }
      if(this.blockAPI.relationships?.fields?.data.find((fv:APIBaseAttributes) => {
        return fv.id === blockIdToSearch
      })) {
        ret = true
      }
    } 
    return ret
  }

  get isConnectionStarted(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return !!connectionStartHandle 
  }

  get isConnectionSuggested(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return ((!!connectionStartHandle && connectionStartHandle.nodeId !== this.props.id) && this.isHovered ) || ( this.isLinkDragged && this.isDraggedOver)
  }

  get isStartOfConnection(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return !!connectionStartHandle && connectionStartHandle.nodeId === this.props.id
  }

  get listConnections(): Connection[] {
    return this.$store.getters['elements/getLinks']
  }

  get isDisappearing(): Connection[] {
    const disappearingElem = this.$store.getters['elements/getDisappearingElement']
    return disappearingElem && disappearingElem.id === this.props.id
  }

  get isSource() {
    return !!this.listConnections.find((connection: Connection) => connection.source === this.props.id )
  }

  get isTarget() {
    return !!this.listConnections.find((connection: Connection) => connection.target === this.props.id )
  }

  get IsButtonDeployed () {
    return (this.isHovered && !this.isConnectionSuggested && !this.isDragged && !this.showBlockBar && !this.isDisappearing)
  }

  get isLinkLeftSelected () {
    const selectedLink = this.$store.getters['elements/getSelectedLink']
    if (selectedLink && this.node) {
      return selectedLink.target === this.node.id
    }
    return false
  }
  get isLinkRightSelected () {
    const selectedLink = this.$store.getters['elements/getSelectedLink']
    if (selectedLink && this.node) {
      return selectedLink.source === this.node.id
    }
    return false
  }

  get isLinkLeftHovered () {
    const hoveredLink = this.$store.getters['elements/getHoveredLink']
    if (hoveredLink && this.node) {
      return hoveredLink.target === this.node.id
    }
    return false
  }
  get isLinkRightHovered () {
    const hoveredLink = this.$store.getters['elements/getHoveredLink']
    if (hoveredLink && this.node) {
      return hoveredLink.source === this.node.id
    }
    return false
  }

  get isLinkDragged () {
    return !!this.$store.getters['elements/getDraggedLink']
  }

  get authoriseHealthCheck() {
    return this.blockStatus !== this.$enums.DeploymentStatus.UNKNOWN && this.blockStatus !== this.$enums.DeploymentStatus.OFFLINE
  }

  onMouseEnter() {
    this.isHovered = true
  }

  onMouseLeave() {
    this.isHovered = false
    this.showBlockBar = false
  }

  onAddNode() {
    this.showBlockBar = false
    this.isHovered = false
  }

  onClick() {
    this.$store.dispatch('elements/setDetailOpenElement', this.node)
  }

  onStatusIndicatorClick(event:MouseEvent) {
    event.stopPropagation()
    if(this.authoriseHealthCheck) {
      API.blocks.getHealthChecks(this.blockAPI.id)
      .then((results:APIHealthCheck[]) => {
        this.healthChecks = results
      })
      this.showHealthCheck = true
    }
  }

  @Watch('isConnectionSuggested')
  onIsConnectionSuggestedChange(newVal:boolean) {
    this.$store.dispatch('elements/setIsLinkSuggested', {node: this.props, value: newVal})
  }

  get positionXYNewBlock() {
    const ret = {x: 0, y: 0}
    ret.x = this.props.position.x + 350
    ret.y = this.props.position.y
    return ret
  }

  // FIRST UNBOARDING
  get needToAppear() {
    return this.block?.key === 'code' && !this.$store.getters['app/getFirstUnboardingDone'] && !this.forcedBlockAPIList
  }
  // END FIRST UNBOARDING

}
</script>

<style lang="scss">
.block-node__hoverable {
  @import '@/css/variables';
  :deep(.health-check-element) {
      cursor: default !important;
  }
  width: 280px;
  // height: 200px;
  .block-node {
    pointer-events: all;
    // height: 124px;
    width: 232px;
    background: white;
    transition: height 0.2s ease-in-out 1s, width 0.2s ease-in-out 1s;
    &.in-error {
      border: 2px solid $color-orange;
      margin-left: -1px;
      margin-top: -1px;
    }
    &.in-warning {
      border: 2px solid $color-yellow;
      margin-left: -1px;
      margin-top: -1px;
    }
    &.in-info {
      border: 2px solid $color-blue;
      margin-left: -1px;
      margin-top: -1px;
    }
    &.needToAppear {
      width: 0px;
      height: 0px;
    }
    .indicator-status {
      position: absolute;
      cursor: pointer;
      left: 160px;
      top:0px;
      padding:12px;
    }
    .warning-picto {
      position: absolute;
      left: 174px;
      top:12px;
      &.warning-picto-severity-error {
        .icon {
          background: $color-orange !important;
        }
      }
      &.warning-picto-severity-warning {
        .icon {
          background: $color-yellow !important;
        }
      }
      &.warning-picto-severity-info {
        .icon {
          background: $color-blue !important;
        }
      }
    }
    .title {
      // background-color: $color-neutral-grey-4;
      color: $color-neutral-black;
      font-weight: 500;
      height: 40px;
      display: flex;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      div {
        margin-top: auto;
        margin-bottom: auto;
        margin-left:8px;
      }
    }
    .handle {
      width: 20px;
      height: 20px;
      background: none;
      display: flex;
      border:none;
      opacity: 1;
      .handle-inside-circle {
        width: 8px;
        height: 8px;
        border-radius: 18px;
        margin: auto;
        pointer-events: none;
      }
      &.hidden {
        opacity: 0;
      }
      &.is-link-selected {
        .handle-interceptor {
          width: 100%;
          height: 100%;
          position: absolute;
          cursor: all-scroll;
        }
      }
      &.is-connecting {
        border: 4px solid $color-brand !important;
        .handle-inside-circle {
          background: $color-brand;
        }
      }
      .handle-display {
        pointer-events: none;
        visibility: hidden;
        background: linear-gradient(0deg, rgba(0, 0, 58, 0.3), rgba(0, 0, 58, 0.3)), #FFFFFF !important;
        border: none;
        width: 8px;
        height: 8px;
        margin: auto;
        border-radius: 20px;
        display: flex;
      }
    }
    .handle-middle {
      border: 1px solid $color-neutral-grey-60;
      top:20px;
      left: 200px;
      &:hover {
        .handle-inside-circle {
          background: $color-neutral-grey-60;
        }
      }
    }
    .handle-left {
      left: -4px;
      // top: 62px;
      transition: border 0.2s ease-in-out;
      pointer-events: none;
      .handle-display {
        margin-left: 0;
      }
      &.is-link-selected {
        .handle-display {
          border: 4px solid $color-brand;
          height: 16px;
          width: 16px;
          margin-left: -5px;
          .handle-continuum {
            width: 6px;
            height: 2px;
            background: #b3b3c4;
            margin: auto;
            margin-left: -5px;
          }
        }
      }
    }
    .handle-right {
      left: 200px;
      // top: 62px;
      transition: border 0.2s ease-in-out;
      pointer-events: none;
      .handle-display {
        margin-right: 0;
        
      }
      &.is-link-selected {
        .handle-display {
          border: 4px solid $color-brand;
          height: 16px;
          width: 16px;
          margin-right: -5px;
          .handle-continuum {
            width: 6px;
            height: 2px;
            background: #b3b3c4;
            margin: auto;
            margin-right: -5px;
          }
        }
      }
    }

    .handle-top {
      left: 116px;
      top: -9px;
      transition: border 0.2s ease-in-out;
      pointer-events: none;

      .handle-display {
        margin-right: 0;
        
      }
      &.is-link-selected {
        .handle-display {
          border: 4px solid $color-brand;
          height: 16px;
          width: 16px;
          margin-top: -5px;
          .handle-continuum {
            width: 6px;
            height: 2px;
            background: #b3b3c4;
            margin: auto;
            margin-right: -5px;
          }
        }
      }
    }

    .handle-bottom {
      left: 116px;
      top: 31px;
      transition: border 0.2s ease-in-out;
      pointer-events: none;

      .handle-display {
        margin-right: 0;
        
      }
      &.is-link-selected {
        .handle-display {
          border: 4px solid $color-brand;
          height: 16px;
          width: 16px;
          margin-bottom: -5px;
          .handle-continuum {
            width: 6px;
            height: 2px;
            background: #b3b3c4;
            margin: auto;
            margin-right: -5px;
          }
        }
      }
    }
  }
  .btn-plus {
    position:absolute;
    z-index: -1;
    right: 100px;
    // top: 46px;
    top: 6px;
    transition: right 0.2s linear, opacity 0s ease-in-out 1.2s;
    &.btn-deployed {
      transition: right 0.2s linear, z-index 0s 0.2s, opacity 0s ease-in-out 1.2s;
      pointer-events: all;
      right: 0;
      z-index: 1;
    }
    &.needToAppear {
      opacity: 0;
    }
  }
  .block-bar-context {
    position: absolute;
    left: 250px;
    top:0;
  }
}

</style>
