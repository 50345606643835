import { APIUser, APISocialAccount, APICustomer } from '@/typesAPI'
import { SubscriptionState } from '@/enums'
import helpers from '@/utils/helpers'
import API from '@/api/wrapper'
import store from '..'
import * as Sentry from "@sentry/vue";

type State = {
  user: APIUser | null,
  customer: APICustomer | null,
  socialAccountsByProvider: {[key:string]: APISocialAccount}
}

const state: State = {
  user: null,
  customer: null,
  socialAccountsByProvider: {}
}


function getSocialAccount(user?:APISocialAccount) {
  if(user && user.relationships?.social_accounts?.data) {
    user.relationships?.social_accounts?.data.forEach((socialAccount:APISocialAccount) => {
      if(!state.socialAccountsByProvider[socialAccount?.attributes?.provider]) {
        API.social.get(socialAccount.id)
        .then((socialAccount:APISocialAccount) => {
          store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
        })
      }
    })
  }
}

const getters = {
  getUser: (state:State) => {
    return state.user
  },
  getOnboardingState: (state:State) => {
    return state.user?.attributes?.onboarding_state
  },
  getCustomer: (state:State) => {
    return state.customer
  },
  getSocialAccountsByProvider: (state:State) => {
    return state.socialAccountsByProvider
  },
  getSubscriptionState: (state:State): string => {
    if(state.user) {
      return state.user.meta.subscription_status
    }
    return SubscriptionState.FRESH
  },
}

const mutations = {
  SET_USER: (state:State, user:APIUser | null) => {
    if(state.user && user) {
      user.attributes.onboarding_state = (user.attributes.onboarding_state === null ? null : JSON.parse(user.attributes.onboarding_state))
      if(helpers.ressources.isNewerThan(user, state.user)) {
        state.user = user
      }
    } else {
      state.user = user
      getSocialAccount(user)
    }
  },
  SET_SOCIAL_ACCOUNT: (state:State, payload: {provider:string, socialAccount:APISocialAccount}) => {
    if(state.socialAccountsByProvider[payload.provider]) {
      if(helpers.ressources.isNewerThan(payload.socialAccount, state.socialAccountsByProvider[payload.provider])) {
        state.socialAccountsByProvider[payload.provider] = payload.socialAccount
      }
    } else {
      state.socialAccountsByProvider[payload.provider] = payload.socialAccount
    }
  },
  DELETE_SOCIAL_ACCOUNT: (state:State, provider:string) => {
    delete state.socialAccountsByProvider[provider]
  },
  SET_CUSTOMER: (state:State, customer:APICustomer) => {
    if(state.customer) {
      if(helpers.ressources.isNewerThan(customer, state.customer)) {
        state.customer = customer
      }
    } else {
      state.customer = customer
    }
  },
  START_TRIAL: (state:State) => {
    if(state.user) {
      state.user.meta.subscription_status = SubscriptionState.TRIALING
    }
  },
}

const actions = {
  setUser: (context:any, user:APIUser | null) => {
    //@ts-ignore
    if(window.hj) {
      //@ts-ignore
      window.hj('identify', user?.id || null, {// Add your own custom attributes here. Some EXAMPLES:
        // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
        // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
        // 'Total purchases': 15, // Send numbers without quotes.
        // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
        // 'Last refund date': null, // Send null when no value exists for a user.
      });
    }
    Sentry.setUser(user ? { 
      email: user.attributes.email,
      id:user.id,
      username: user.attributes.full_name
    } : null);

    context.commit('SET_USER', user)
  },
  setSocialAccount: (context:any,  payload: {provider:string, socialAccount:APISocialAccount}) => {
    context.commit('SET_SOCIAL_ACCOUNT', payload)
  },
  deleteSocialAccount: (context:any,  provider:string) => {
    context.commit('DELETE_SOCIAL_ACCOUNT', provider)
  },
  setCustomer: (context:any, customer:APICustomer) => {
    context.commit('SET_CUSTOMER', customer)
  },
  startTrial: (context:any) => {
    context.commit('START_TRIAL')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}